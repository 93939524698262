<!--
 * @Author: your name
 * @Date: 2020-12-29 12:19:15
 * @LastEditTime: 2023-02-01 17:06:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BusinessPlatform\src\components\businessInstance\processArea\flowChart.vue
-->
<template>
    <div class="FlowChart">
        <ul>
            <template v-for="(item, index) in flowChartData">
                <li :key="`flowChartNode_${index}`" :class="nodeClassNmae(item)">
                    <template v-if="item.PhaseType !=3">
                        <div class="nodeTitle">
                            当前环节
                        </div>
                        <div class="nodeList iconfont iconjiantou_yemian_xiangyou">
                            <h4 class="nodeName">
                                {{ item.BusinessPhaseName }}
                            </h4>
                            <template v-if="item.IsHandled">
                                <template v-for="(k, j) in item.HandledUsers">
                                    <div class="nodeContent" :key="`approver_${j}_${k.ApproverId}`">
                                        <div>
                                            <span class="approverName">{{ k.ApproverName }}</span>
                                            <span class="participantAction">{{ k.ParticipantAction }}</span>
                                        </div>
                                        <p class="endTime">
                                            {{ k.EndTime || '-- ：-- ：--' }}
                                        </p>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="nodeContent">
                                    <div>
                                        <span class="approverName">
                                            <template v-if="item.ApproverType == 1">
                                                {{ item.ApproverName }}
                                            </template>
                                            <template v-if="item.ApproverType == 2">
                                                {{ item.ApproverRoleName }}
                                            </template>
                                        </span>
                                        <span class="participantAction">待审批</span>
                                    </div>
                                    <p class="endTime">
                                        {{ '-- ：-- ：--' }}
                                    </p>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="nodeTitle">
                            会签
                        </div>
                        <template v-for="(a,s) in item.countersigning">
                            <div
                                class="nodeList iconfont iconjiantou_yemian_xiangyou"
                                :key="`countersigning_${s}`"
                                :class="{'nodeListHandled':a.IsHandled}"
                            >
                                <h4 class="nodeName">
                                    {{ a.BusinessPhaseName }}
                                </h4>
                                <template v-if="a.IsHandled">
                                    <template v-for="(k, j) in a.HandledUsers">
                                        <div class="nodeContent" :key="`approver_${j}_${k.ApproverId}`">
                                            <div>
                                                <span class="approverName">{{ k.ApproverName }}</span>
                                                <span class="participantAction">{{ k.ParticipantAction }}</span>
                                            </div>
                                            <p class="endTime">
                                                {{ k.EndTime || '-- ：-- ：--' }}
                                            </p>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="nodeContent">
                                        <div>
                                            <span class="approverName">
                                                <template v-if="a.ApproverType == 1">
                                                    {{ a.ApproverName }}
                                                </template>
                                                <template v-if="a.ApproverType == 2">
                                                    {{ a.ApproverRoleName }}
                                                </template>
                                            </span>
                                            <span class="participantAction">待审批</span>
                                        </div>
                                        <p class="endTime">
                                            {{ '-- ：-- ：--' }}
                                        </p>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    // 弹出流程图
    name: 'flow-chart',
    data() {
        return {
            flowChartData: [],
        };
    },
    props: {
        extr: {
            type: [Array, Object, String ],
            required: true, // 是否必传
        },
    },
    async created() {
        const row = await this.getFlowChartData(this.extr.businessInstanceId);
        const PhaseGroupIdS = [];
        row.forEach(item => {
            if (item.PhaseType !== 3) {
                this.flowChartData.push(item);
            } else if (!PhaseGroupIdS.includes(item.PhaseGroupId)) {
                PhaseGroupIdS.push(item.PhaseGroupId);
                const countersigning = row.filter(k => k.PhaseGroupId === item.PhaseGroupId);
                const IsHandled = countersigning.some(item => {return item.IsHandled === true;});
                countersigning.sort((a, b) => {
                    return a.PhaseGroupSort - b.PhaseGroupSort;
                });
                this.flowChartData.push({
                    PhaseType: 3,
                    countersigning,
                    IsHandled,
                });
            }
        });
    },
    updated() {},
    components: {},
    methods: {
        nodeClassNmae(item) {
            let returnClass = '';
            // 已审批环节
            if (item.IsHandled) {
                returnClass = `${returnClass} approvedNode`;
            }
            // 当前环节
            if (item.IsCurrentHandledPhase) {
                returnClass = `${returnClass} currentNode`;
            }
            // 会签环节
            if (item.PhaseType === 3) {
                returnClass = `${returnClass} countersigning`;
            }
            return returnClass;
        },
        getFlowChartData(businessInstanceId) {
            const path = `/interfaceApi/basicplatform/simpleflow/processes/phases_handling/${businessInstanceId}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>

<style lang="stylus" scoped>
// 弹出流程图样式
.FlowChart
    height 100%
    padding 0.1rem
    background: linear-gradient(134deg, #C1D7EF 0%, #EAF6F5 100%);
    ul
        padding: 0.2rem
        overflow-y auto
        background: #fff
        display flex
        flex-wrap wrap
        justify-content flex-start
        li
            min-width 1.8rem
            background #FFFFFF
            margin: 0rem 0.5rem 0.2rem 0
            &:last-child
                .nodeList
                    &:before
                        display none
            // 已审批环节
            &.approvedNode,&.currentNode
                .nodeList
                    border-color #0078FB
                    .nodeName
                        color #0078FB
                        background: #EAF1FE
                    .nodeContent
                        .participantAction
                            color #0078FB
            // 当前环节
            &.currentNode
                .nodeTitle
                    visibility visible
                    background: #0078FB
                    &:before
                        background: url(./images/nodeTitle_bg_h.png) no-repeat
                .nodeList
                    border 2px solid #0078FB
            // 会签环节
            &.countersigning
                &.approvedNode
                    .nodeTitle
                        background: #0078FB
                        &:before
                            background: url(./images/nodeTitle_bg_h.png) no-repeat
                .nodeTitle
                    visibility visible
                .nodeList
                    &:nth-child(n + 2)
                        &:before
                            display: none
                .nodeListHandled
                    border-color #0078FB
                    .nodeName
                        color #0078FB
                        background: #EAF1FE
                    .nodeContent
                        .participantAction
                            color #0078FB
            .nodeTitle
                display inline-block
                font-size 0.12rem
                color #fff
                padding 0 0.08rem
                line-height: 0.2rem
                background: #B0B0B0
                margin-bottom: 0.04rem
                position: relative
                visibility hidden
                vertical-align top
                &:before
                    content ''
                    width 0.06rem
                    height: 0.2rem
                    position: absolute
                    top 0
                    left 100%
                    background: url(./images/nodeTitle_bg.png) no-repeat
                    background-size: 100%
            .nodeList
                box-shadow: 0px 0px 10px 0px #E2E2E2;
                border-top 2px solid #B0B0B0
                position: relative
                &:before
                    position: absolute
                    left: 100%
                    font-size: 0.32rem
                    color: #bbbbbb
                    width 0.5rem
                    height: 0.88rem
                    line-height: 0.88rem
                    text-align center
                .nodeName
                    height: 0.32rem
                    font-size: 0.16rem
                    line-height: 0.32rem
                    color #333
                    background: #F3F3F3
                    padding 0 0.1rem
                .nodeContent
                    padding 0.1rem
                    position: relative
                    &:before
                        content ''
                        width calc(100% - 0.2rem)
                        position: absolute
                        top 100%
                        left 0.1rem
                        border-top: 1px solid #F3F3F3
                    &:last-child
                        &:before
                            display none
                    >div
                        margin-bottom: 0.08rem
                        display flex
                        justify-content space-between
                        .approverName
                            font-size: 0.14rem;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            line-height: 0.14rem
                            color #001A2C
                            margin-right: 0.2rem
                        .participantAction
                            font-size: 0.12rem;
                            line-height: 0.14rem
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color #B0B0B0
                    .endTime
                        height 0.14rem
                        font-size: 0.12rem
                        line-height: 0.14rem
                        color #868FA3
</style>
